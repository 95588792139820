/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/pace-js@1.2.4/pace.min.js
 * - /npm/moment@2.22.2/moment.min.js
 * - /npm/moment-timezone@0.5.17/builds/moment-timezone-with-data.min.js
 * - /npm/sortablejs@1.15.0/Sortable.min.js
 * - /npm/jquery-validation@1.17.0/dist/jquery.validate.min.js
 * - /npm/timecircles@1.5.3/inc/TimeCircles.min.js
 * - /npm/rateyo@2.3.2/min/jquery.rateyo.min.js
 * - /npm/country-region-selector@0.4.0/dist/crs.min.js
 * - /npm/current-device@0.7.8/umd/current-device.min.js
 * - /npm/geolocator@2.1.3/dist/geolocator.min.js
 * - /npm/jquery-ui-touch-punch@0.2.3/jquery.ui.touch-punch.min.js
 * - /npm/autosize@4.0.0/dist/autosize.min.js
 * - /npm/list.js@1.5.0/dist/list.min.js
 * - /npm/jquery-hashtags@0.5.0/js/jquery.hashtags.min.js
 * - /npm/quill@1.3.7/dist/quill.min.js
 * - /npm/dropify@0.2.2/dist/js/dropify.min.js
 * - /npm/dropzone@5.9.3/dist/min/dropzone.min.js
 * - /npm/fullcalendar@3.10.5/dist/fullcalendar.min.js
 * - /npm/qtip2@3.0.3/dist/jquery.qtip.min.js
 * - /npm/video.js@6.6.3/dist/video.min.js
 * - /npm/recordrtc@5.4.6/RecordRTC.min.js
 * - /npm/videojs-record@2.0.6/dist/videojs.record.min.js
 * - /npm/humanize-plus@1.8.2/dist/humanize.min.js
 * - /npm/typed.js@2.0.12/lib/typed.min.js
 * - /npm/gridjs@5.0.2/dist/gridjs.umd.js
 * - /npm/ua-parser-js@0.7.28/src/ua-parser.min.js
 * - /npm/@popperjs/core@2.9.3/dist/umd/popper.min.js
 * - /npm/tippy.js@6.3.1/dist/tippy-bundle.umd.min.js
 * - /npm/packery@2.1.2/dist/packery.pkgd.js
 * - /npm/draggabilly@3.0.0/dist/draggabilly.pkgd.min.js
 * - /npm/jquery-clock-plugin@2.3.4/jqClock.min.js
 * - /npm/jquery-confirm@3.3.4/dist/jquery-confirm.min.js
 * - /npm/clipboard@2.0.10/dist/clipboard.min.js
 * - /npm/chart.js@4.3.0/dist/chart.umd.min.js
 * - /npm/jquery.repeater@1.2.1/jquery.repeater.min.js
 * - /npm/select2@4.0.13/dist/js/select2.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
